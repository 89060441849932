<template>
  <div class="about-page">
    <LoadingSpinner v-if="loading" />

    <template v-else-if="!exception">
      <PageBanner
        :background="content.fields.pageBanner.fields.background"
        :heading="content.fields.pageBanner.fields.heading"
        :subheading="content.fields.pageBanner.fields.subheading"
        class="about-page-banner" />
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <RichTextRenderer
              :document="content.fields.content" />

            <MainButton
              label="Kontakta oss"
              class="about-page-button"
              :expanded="false"
              @click="goToContactUs" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PageBanner from '@/components/views/PageBanner'
import LoadingSpinner from '@/components/LoadingSpinner'
import MainButton from '@/components/MainButton'
import { RouteName } from '@/router/router-constants'

import RichTextRenderer from 'contentful-rich-text-vue-renderer'

import {
  mapActions,
  mapState
} from 'vuex'

export default {
  components: {
    MainButton,
    LoadingSpinner,
    PageBanner,
    RichTextRenderer
  },
  computed: {
    ...mapState('AboutPage', [
      'loading',
      'exception',
      'content'
    ])
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('AboutPage', ['loadContent']),
    goToContactUs () {
      this.$router.push({ name: RouteName.public.Contact })
    }
  }
}
</script>

<style lang="scss" scoped>
.about-page {
  margin-bottom: 50px;

  &-banner {
    margin-bottom: 40px;
  }

  &-button {
    margin-top: 40px;
  }
}
</style>
